import Login from '../pages/admin'
const publicRoutes = [
  {
    Component: Login,
    exact: true,
    index: true,
  },
]

export default publicRoutes
